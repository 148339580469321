.ebr-primary-btn {
    @include font($font-size-14, $font-weight-regular, $color-white, 1.42);

    background-color: $color-primary-blue;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    text-align: center;

    &:hover {
        background-color: $color-brand-dark-blue;
        box-shadow: 0 3px 10px 0 rgba(97, 99, 101, 0.15);
    }

    &:focus {
        background-color: $color-light-blue;
        border: 1px solid $color-primary-blue;
        color: $color-primary-blue;
    }

    &:disabled {
        border-color: $color-button-disabled;
        background-color: $color-button-disabled;
        color: $color-button-disabled-text;
    }

    img {
        padding-right: 8px;
    }
}

.ebr-secondary-btn {
    @include font($font-size-14, $font-weight-regular, $color-primary-blue, 1.42);

    background-color: $color-white;
    border: 1px solid $color-primary-blue;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 40px;
    padding: 8px 16px;
    text-align: center;

    &:hover {
        background-color: $color-light-blue;
        box-shadow: 0 3px 10px 0 rgba(97, 99, 101, 0.15);
    }

    &:focus {
        background-color: $color-light-blue;
        border: 1px solid $color-primary-blue;
        color: $color-primary-blue;
    }

    &:disabled {
        background: none;
        border-color: $color-button-disabled;
        color: $color-button-disabled-text;
    }

    img {
        padding-right: 8px;
    }
}

.ebr-text-btn {
    @include font($font-size-14, $font-weight-regular, $color-primary-blue, 1.42);

    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    text-align: center;

    &:hover {
        text-decoration: underline;
    }

    &:focus {
        border: 1px solid $color-primary-blue;
        color: $color-primary-blue;
    }

    &:disabled {
        color: $color-button-disabled-text;
    }

    img {
        padding-right: 8px;
    }
}