.ebr-icon {
    display: inline-flex;
    width: 24px;
    height: 24px;

    @include MediaQueries($desktop-and-above) {
        width: 32px;
        height: 32px;
    }
    padding-left: 40px;
    background-size: 32px 32px !important;
    width: 100% !important;
    @include MediaQueries($below-desktop) {
        font-size: $font-size-22;
        height: 32px;
        line-height:$line-height-28;
        margin-bottom: 10px;
    }
}

.icon-water {
    @extend .ebr-icon;
    background: url('../images/icon-water.png') no-repeat left center;
    
}

.icon-energy {
    @extend .ebr-icon;
    background: url('../images/icon-energy.png') no-repeat left center;
}

.icon-waste {
    @extend .ebr-icon;
    background: url('../images/icon-waste.png') no-repeat left center;
}

.icon-cost-benefit {
    @extend .ebr-icon;
    background: url('../images/icon-cost-benefit.png') no-repeat left center;
}

.icon-food-safety {
    @extend .ebr-icon;
    background: url('../images/icon-food-safety.png') no-repeat left center;
}

.icon-asset-protection {
    @extend .ebr-icon;
    background: url('../images/icon-asset-protection.png') no-repeat left center;
}

.icon-human-safety {
    @extend .ebr-icon;
    background: url('../images/icon-human-safety.png') no-repeat left center;
}

.icon-labor-productivity {
    @extend .ebr-icon;
    background: url('../images/icon-labor-productivity.png') no-repeat left center;
}

.icon-delighted-guests {
    @extend .ebr-icon;
    background: url('../images/icon-delighted-guests.png') no-repeat left center;
}

.icon-regulatory-compliance {
    @extend .ebr-icon;
    background: url('../images/icon-regulatory-compliance.png') no-repeat left center;
}

.icon-water {
    @extend .ebr-icon;
    background: url('../images/icon-water.png') no-repeat left center;
}

.icon-water-safety {
    @extend .ebr-icon;
    background: url('../images/icon-water.png') no-repeat left center;
}

.icon-brand-protection {
    @extend .ebr-icon;
    background: url('../images/icon-brand-protection.png') no-repeat left center;
}

.icon-industry-compliance {
    @extend .ebr-icon;
    background: url('../images/icon-regulatory-compliance.png') no-repeat left center;
}

.icon-brand {
    @extend .ebr-icon;
    background: url('../images/icon-brand-protection.png') no-repeat left center;
}

.icon-regulatory-compliance{
    @extend .ebr-icon;
    background: url('../images/icon-regulatory-compliance.png') no-repeat left center;
}

.icon-reliability{
    @extend .ebr-icon;
    background: url('../images/icon-reliability.png') no-repeat left center;
}

.icon-capacity{
    @extend .ebr-icon;
    background: url('../images/icon-capacity.png') no-repeat left center;
}

.icon-location-productivity{
    @extend .ebr-icon;
    background: url('../images/icon-location-productivity.png') no-repeat left center;
}

.icon-product-optimization{
    @extend .ebr-icon;
    background: url('../images/icon-product-optimization.png') no-repeat left center;
}

.icon-raw-material-optimization{
    @extend .ebr-icon;
    background: url('../images/icon-raw-material-optimization.png') no-repeat left center;
}

.icon-quality {
    @extend .ebr-icon;
    background: url('../images/icon-quality.png') no-repeat left center;
}