@mixin font(
    $fontSize: '',
    $fontWeight: normal,
    $color: '',
    $line-height: 1,
    $letter-spacing: normal,
    $fontStyle: normal,
    $fontFamily: Roboto
) {
    @if $fontSize {
        font-size: $fontSize;
    }

    @if $fontWeight {
        font-weight: $fontWeight;
    }

    @if $color {
        color: $color;
    }

    @if $line-height {
        line-height: $line-height;
    }

    @if $letter-spacing {
        letter-spacing: $letter-spacing;
    }

    @if $fontStyle {
        font-style: $fontStyle;
    }

    @if $fontFamily {
        font-family: $fontFamily;
    }
}

@mixin MediaQueries($mediaquery) {
    @media #{$mediaquery} {
        @content;
    }
}
