.grid {
    align-items: $align-items-center-self-start;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16px;

    @include MediaQueries($tablet-and-above) {
        grid-template-columns: repeat(8, 1fr);
    }

    @include MediaQueries($desktop-and-above) {
        grid-template-columns: repeat(12, 1fr);
        gap: 24px;
    }
}

.container {
    width: 100%;
    padding: 0 16px;

    @include MediaQueries($tablet-and-above) {
        margin: 0 auto;
        padding: 0 32px;
    }

    @include MediaQueries($desktop-and-above) {
        padding: 0 24px;
        max-width: 1308px;
        margin: 0 auto;
    }
}

.container-fluid {
    max-width: 100%;
    margin: 0;
    padding: 0 16px;

    @include MediaQueries($tablet-and-above) {
        padding: 0 32px;
    }

    @include MediaQueries($desktop-and-above) {
        padding: 0 24px;
    }
}

.col-lg-offset-2 {
    grid-column: 3/12;
    @include MediaQueries($large-desktop-and-above) {
        grid-column: 3/11;
    }
    @include MediaQueries($extralarge-desktop-and-above) {
        grid-column: 4/11;
    }
    @include MediaQueries($ultralarge-desktop-and-above) {
        grid-column: 4/10;
    }
}

.col-1 {
    grid-column: span 1;
}

.col-2 {
    grid-column: span 2;
}

.col-3 {
    grid-column: span 3;
}

.col-sm-full {
    grid-column: span 4;
}

.col-full {
    width: 100%;
}

@include MediaQueries($tablet-and-above) {
    // Total Grid Columns in Desktop = 12
    // Total Grid Columns in Tablet = 8
    .col-md-1 {
        grid-column: span 1;
    }

    .col-md-2 {
        grid-column: span 2;
    }

    .col-md-3 {
        grid-column: span 3;
    }

    .col-md-4 {
        grid-column: span 4;
    }

    .col-md-5 {
        grid-column: span 5;
    }

    .col-md-6 {
        grid-column: span 6;
    }

    .col-md-7 {
        grid-column: span 7;
    }

    .col-md-full {
        grid-column: span 8;
    }
}

@include MediaQueries($desktop-and-above) {
    .col-lg-1 {
        grid-column: span 1;
    }

    .col-lg-2 {
        grid-column: span 2;
    }

    .col-lg-3 {
        grid-column: span 3;
    }

    .col-lg-4 {
        grid-column: span 4;
    }

    .col-lg-5 {
        grid-column: span 5;
    }

    .col-lg-6 {
        grid-column: span 6;
    }

    .col-lg-7 {
        grid-column: span 7;
    }

    .col-lg-8 {
        grid-column: span 8;
    }

    .col-lg-9 {
        grid-column: span 9;
    }

    .col-lg-10 {
        grid-column: span 10;
    }

    .col-lg-11 {
        grid-column: span 11;
    }

    .col-lg-full {
        grid-column: span 12;
    }
}

.erb-flex {
    display: flex;
}

.erb-flex-col {
    flex-direction: column;
}

.flex-full {
    flex: 1;
}

.flex-between {
    justify-content: space-between;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-center {
    align-items: center;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.hide {
    display: none;
}

.hide-below-desktop {
    @include MediaQueries($mobile) {
        display: none;
    }
    @include MediaQueries($tablet-and-above) {
        display: block;
    }
}

.hide-above-desktop{
    @include MediaQueries($tablet-and-above) {
        display: none;
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }

@include MediaQueries($below-desktop) {
    .ebr-primary-btn{
        width: 100%;
        justify-content: center;
      }
}

@include MediaQueries($mobile) {
    .home-menu-button{
        cursor: pointer;
      }
      .footer {
        flex-direction: row;
        flex-wrap: wrap;
        &__logo{
            order:3;
            justify-content: flex-end;
            display: flex;
        }
        &__links{
            order:1;
            flex: 1 1 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            padding-bottom: 16px;
        }
        &__currentYear{
            order:2;      
        }
        &__logo,&__currentYear{
            flex: 1 1 50%; 
        }
    }
        .logout-button {
            height: 65px !important;
            width: 34rem !important;
        }
    
        .main-toolbar-title-mob {
            font-size: clamp(1rem, 2vw + 1rem, 2rem);
        }
    
        .font-22 {
            font-size: $font-size-16;
        }
    
        .business-review__header {
            margin-top: 32px !important
        }
     .dropdown-button{
        margin-left: 0;
     }
     .business-review__header_operating-type{
        padding: 16px 0 16px 0 !important;
     }
     .form-row{
        flex-direction: column;
     }
     .kbd-card-container{
        flex-direction: column;
     }
}