/* You can add global styles to this file, and also import other style files */
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html, body { height: 100%; margin: 0;}

.cdk-global-scrollblock{
 position: static !important;
 overflow: hidden !important;
}
@import "./assets/one-customer-styles/main.scss";


.kbd-container .mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 1px;
}

.mat-mdc-snack-bar-container {
  &.success-snackbar {
    text-align: center;
    --mdc-snackbar-container-color: green;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
  &.default {
    text-align: center;
    --mdc-snackbar-container-color: #14171a;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.mat-mdc-snack-bar-container {
  &.error-snackbar {
    text-align: center;
    --mdc-snackbar-container-color: red;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
  }
}

.mdc-list-item__primary-text {
  color: #093675 !important;
}

.mdc-snackbar__label {
  padding: 14px 8px 14px 0px !important;
}

.mat-mdc-snackbar-surface {
  padding-right: 0px !important;
}

// *,
// *:before,
// *:after {
//     -webkit-box-sizing: inherit;
//     -moz-box-sizing: inherit;
//     box-sizing: inherit;
//     margin: 0;
//     padding: 0;
// }

// html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.mat-horizontal-content-container {
  overflow: unset !important;
}
.mat-expansion-panel-body {
  padding:0 !important;
}