.tile {
    .mat-expansion-panel-header .mat-content {
        padding-right: 16px !important;
        flex: none !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        font-family: 'Roboto' !important;
        line-height: 1.42 !important;
    }

    .mat-expansion-indicator {
        width: 24px;
        height: 24px;

        &::after {
            content: '';
            padding: 3px !important;
            border-width: 0 3px 3px 0 !important;
            color: #006BD3 !important;
        }
    }

    &.level-one {
        .mat-expansion-indicator::after {
            color: white !important;
        }
    }
}

.mat-stepper-horizontal {
    background: transparent !important;

    .mat-horizontal-content-container {
        padding: 0 !important;
    }
}