// MEDIA QUERY BREAK POINTS
// calculation -  device-width/16px = break point in em
$bp-laptop-L: 90em; // 1440px
$bp-laptop-M: 64em; // 1024px
$bp-laptop-S: 62.5em; // 1000px
$bp-tab: 48em; // 800px
$bp-mobile: 37.5em; // 600px

// FONT FAMILY
$font-family: Roboto, "Helvetica Neue", sans-serif;

// CSS GLOBAL RESET
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}



html {
  box-sizing: border-box;
  font-size: 62.5%; // 1rem = 10px; 10px/16px = 62.5%

  @media only screen and (max-width: $bp-laptop-M) {
    font-size: 50%;
  }

  @media only screen and (max-width: $bp-laptop-S) {
    font-size: 40%;
  }
}

body {
  font-family: $font-family;
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}