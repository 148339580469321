// COLOR VARIABLES
$color-white: #FFFFFF;
$color-blue: rgb(31, 88, 209);
$color-black: #000000;
$color-primary-blue: #006BD3;
$color-brand-dark-blue: #093675;
$color-dark-grey: #222222;
$color-light-grey: #616365;
$color-secondary-blue: #082344;
$color-dark-blue: #044EA0;
$color-opportunity: #4fc2b0;
$color-light-blue: #D9EAF9;
$color-button-disabled: #EFEFEF;
$color-button-disabled-text: #ADAFAF;
$color-success: #39b54a;
$color-error: #df3935;
$color-warning: #ecc42f;
$color-bg-surface: #EDF4FA;
$color-input-bg: #F5FAFD;
$color-input-text: #14171A;
$color-note:#5B6065;
$color-primary-red:#E53900;
$color-bg-loader-overlay: rgba(52, 52, 52, 0.32);

//FONT SIZES
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-heading: 24px;
$font-size-28: 28px;
$font-size-32: 32px;
$font-size-40: 40px;
$font-size-45: 45px;
$font-size-52: 52px;
$font-size-57: 57px;
$font-size-3-2rem :3.2rem;

// FONT WEIGHT
$font-weight-thin: 200;
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 500;
$font-weight-bold: 700;

// PADDING and MARGIN
$spacing-2: 2px;
$spacing-3: 3px;
$spacing-5: 5px;
$spacing-6: 6px;
$spacing-8: 8px;
$spacing-10: 10px;
$spacing-12: 12px;
$spacing-16: 16px;
$spacing-18: 18px;
$spacing-22: 22px;
$spacing-24: 24px;
$spacing-30: 30px;
$spacing-36: 36px;
$spacing-28: 28px;

// FONT STYLES
$font-style-regular: normal;
$font-style-italic: italic;

// Font Family 
$font-family-primary: Roboto, "Helvetica Neue", sans-serif;
$font-family-secondary: Roboto, "Regular", sans-serif;

// Breakpoints
$mq__lg: 1024px;
$mq__extra_lg:1440px;
$mq__xxl: 1600px;
$mq__xxxl: 1800px;
$mq__md: 720px;
$screen: unquote('only screen');

$desktop-and-above: unquote('#{$screen} and (min-width: #{$mq__lg})');
$between-desktop-and-large: unquote('#{$screen} and (min-width: #{$mq__lg}) and (max-width: #{$mq__extra_lg})');
$large-desktop-and-above: unquote('#{$screen} and (min-width: #{$mq__extra_lg})');
$extralarge-desktop-and-above: unquote('#{$screen} and (min-width: #{$mq__xxl})');
$ultralarge-desktop-and-above: unquote('#{$screen} and (min-width: #{$mq__xxxl})');
$below-desktop: unquote('#{$screen} and (max-width: #{$mq__lg})');
$tablet-and-above: unquote('#{$screen} and (min-width: #{$mq__md})');
$mobile: unquote('#{$screen} and (max-width: #{$mq__md})');

// WIDTH VARIABLES
$tiles-width: calc(600px / 3);

// Backdrop height
$backdrop-height: 345px;

// LINE HEIGHT
$line-height-20: 20px;
$line-height-26: 26px;
$line-height-28: 28px;
$line-height-40: 40px;

//GAP
$gap-0: 0px;
$gap-16: 16px;
$gap-8: 8px;
$gap-20: 20px;

//HEIGHT
$height-16: 16px;
$height-20: 20px;
$height-24: 24px;
$height-32: 32px;
$height-40: 40px;

//WIDTH
$width-24: 24px;
$width-32: 32px;
$width-82: 82px;
$width-88: 88px;
$width-96: 96px;
$width-360: 360px;

//BORDER RADIUS
$border-radius-4: 4px;

//PADDING
$padding-1: 12px 26px 12px 26px;
$padding-2: 8px 16px 8px 16px;
$padding-3: 14px 16px 14px 16px;

//BORDER WIDTH
$border-width-1: 0px 0px 1px 0px;

$line-height-1_14: 1.14; 
$white-space-break-spaces: break-spaces; 
$letter-spacing-normal: normal;
$font-size-clamp: clamp(3rem, 2vw + 1rem, 3rem);
$align-items-center: center;
$align-items-center-self-start: self-start;